import React from 'react'

export type Route = 'lab' | 'garden'

interface Props {
  onNavigate: (route: Route) => void
  onSeed: () => void
  route: Route
  onClickAbout: () => void
}

function MainToolbar ({ onNavigate, route, onSeed, onClickAbout }: Props) {
  return (
    <div className='main-toolbar'>
      <div className='button-group'>
        <button type='button' disabled={route === 'lab'} className='button' onClick={() => onNavigate('lab')}>lab</button>
        <button type='button' disabled={route === 'garden'} className='button' onClick={() => onNavigate('garden')}>garden</button>
      </div>
      {route === 'lab' && (
        <div className='button-group'>
          <button type='button' className='button' onClick={() => onSeed()}>↻</button>
        </div>
      )}
      <div className='button-group' style={{ marginLeft: 'auto' }}>
        <button type='button' className='button button-about' onClick={() => onClickAbout() }>about</button>
      </div>
    </div>
  )
}

export default MainToolbar
