import { MAX_DIMENSIONS } from './CGP'
import { SVGInstance } from './ImageFlipBuffer'

export type ChannelAddress = number
export type Channel = number
export type Color = [Channel, Channel, Channel, Channel]
export type ColorPointer = [ChannelAddress, ChannelAddress, ChannelAddress, ChannelAddress]
export type Palette = ColorPointer[]
export const CHANNEL_ALPHA = 3
export type GradientType = 'linear' | 'radial'

export const randomChannelAddress = (): Channel => Math.random() * (MAX_DIMENSIONS.memory - 1) << 0
export const randomColorArray = (): ColorPointer => [
  randomChannelAddress(),
  randomChannelAddress(),
  randomChannelAddress(),
  randomChannelAddress(),
]

export const dim = (color: Color, d: number): Color => [
  (color[0] * d) << 0,
  (color[1] * d) << 0,
  (color[2] * d) << 0,
  color[3] / 255 * 0.85,
]

export const toCSSRGB = (rgbArray: Color) => `rgb(${rgbArray[0] << 0},${rgbArray[1] << 0},${rgbArray[2] << 0})` // eslint-disable-line

interface ColorStop {
  at: Function
}

export function dimmedGradient (
  color: Color,
  svg: SVGInstance,
  direction: boolean,
  gradientDimFactor: number,
  gradientType: GradientType,
): any { // eslint-disable-line
  const dimmedColor: Color = dim(color, gradientDimFactor)
  const result = svg.gradient(gradientType, function (stop: ColorStop) {
    stop.at({
      offset: 0,
      color: (direction ? toCSSRGB(dimmedColor) : toCSSRGB(color)),
      opacity: 1, // (direction ? dimmedColor[3] / 255 : color[3] / 255),
    })
    stop.at({
      offset: 1,
      color: (!direction ? toCSSRGB(dimmedColor) : toCSSRGB(color)),
      opacity: 1, // (!direction ? dimmedColor[3] / 255 : color[3] / 255),
    })
  })

  if (gradientType === 'radial') {
    return result.fill()
  }

  return result
    .from(0, 0)
    .to(0, 1)
}
