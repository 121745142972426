export default class FavToolbarMobile {
  private static readonly instances: FavToolbarMobile[] = []
  public onClickRemove: Function = (): void => {} // eslint-disable-line
  public onClickMutate : Function = (): void => {} // eslint-disable-line
  public onClickAdopt : Function = (): void => {} // eslint-disable-line
  private readonly element: HTMLDivElement
  private readonly buttonGroup: HTMLDivElement
  private readonly adoptButton: HTMLButtonElement
  private readonly mutateButton: HTMLButtonElement
  private readonly removeButton: HTMLButtonElement
  private isVisible = false

  constructor (parentElement: HTMLElement) {
    this.element = document.createElement('div')
    this.element.className = 'toolbar'

    this.buttonGroup = document.createElement('div')
    this.element.className = 'button-group'
    this.element.append(this.buttonGroup)

    parentElement.addEventListener('touchstart', e => {
      e.stopPropagation()
      setTimeout(() => {
        if (!this.isVisible) {
          FavToolbarMobile.hideAll()
          this.visible = true
        } else {
          FavToolbarMobile.hideAll()
        }
      }, 200)

      return false
    })

    this.removeButton = document.createElement('button')
    this.removeButton.className = 'button'
    this.removeButton.innerHTML = 'free'

    this.removeButton.addEventListener('touchstart', e => {
      if (this.isVisible) {
        this.onClickRemove()
        e.stopPropagation()
      }
    })

    this.mutateButton = document.createElement('button')
    this.mutateButton.className = 'button'
    this.mutateButton.innerHTML = 'fork'

    this.mutateButton.addEventListener('touchstart', e => {
      if (this.isVisible) {
        e.stopPropagation()
        FavToolbarMobile.hideAll()
        setTimeout(() => {
          this.onClickMutate()
        }, 600)
      }
    })

    this.adoptButton = document.createElement('button')
    this.adoptButton.className = 'button'
    this.adoptButton.innerHTML = 'perma'
    this.adoptButton.addEventListener('touchstart', e => {
      e.stopPropagation()
      this.onClickAdopt()
    })

    this.buttonGroup.appendChild(this.mutateButton)
    this.buttonGroup.appendChild(this.adoptButton)
    this.buttonGroup.appendChild(this.removeButton)
    this.element.className = 'toolbar'
    parentElement.appendChild(this.element)
    FavToolbarMobile.instances.push(this)
    this.update()
  }

  set visible (value: boolean) {
    this.isVisible = value
    this.update()
  }

  static hideAll () {
    FavToolbarMobile.instances.forEach((instance: FavToolbarMobile) => {
      instance.visible = false
    })
  }

  static set allEnabled (value: boolean) { } // eslint-disable-line
  update (): void {
    this.element.style.opacity = (this.isVisible)
      ? '1'
      : '0'
  }
}
