import React, { Component } from 'react'
import { sample, random } from 'lodash'
import Parser from './Parser'
import { Viewport, sleep, measureDuration, NOOP, isTouchDevice } from './utils'
import { EMPTY_WORK_COMPLEXITY } from './const'
import { isInteractive } from './settings'
import { GridSizeUnits } from './gridSize'
import MutateToolbar from './MutateToolbar'
import MutateToolbarMobile from './MutateToolbarMobile'
import { fitnessFunction } from './fitness' // findSuitableRandomDrawing
import CGPGrid from './CGP'
import ImageFlipBuffer from './ImageFlipBuffer'

interface Props {
  visible: boolean
  drawing: CGPGrid
  onClickRemember: Function
  gridSizeUnits: GridSizeUnits
  drawingSize: Viewport
}

const Toolbar = isTouchDevice ? MutateToolbarMobile : MutateToolbar

export default class MutatorScreen extends Component<Props> {
  private readonly drawings: CGPGrid[] = []
  private variationsBuffers: ImageFlipBuffer[] = []
  private breedCounter = 0
  private firstTime = true
  private lastChosen = 0
  private busyAutobreeding = false
  private previousLargestComplexity = 0
  private containerElement: HTMLDivElement | null = null

  mutate = async (index: number): Promise<void> => {
    if (this.busyAutobreeding) return
    this.busyAutobreeding = true
    Toolbar.allEnabled = false
    await this.choose(index)
  }

  createFlipBuffer (index: number): ImageFlipBuffer {
    if (this.containerElement === null) throw new Error('Parent container not initialised')
    const { drawingSize, gridSizeUnits } = this.props

    const imageFlipBuffer = ImageFlipBuffer.factory(this.containerElement, index, drawingSize, gridSizeUnits)

    const mutateToolbar = new Toolbar(imageFlipBuffer.element)

    mutateToolbar.onClickRemember = () =>
      this.props.onClickRemember(this.drawings[index], index)

    mutateToolbar.onClickMutate = () => this.mutate(index)

    if (!isTouchDevice) {
      imageFlipBuffer.onClick = () => this.mutate(index)

      imageFlipBuffer.element.addEventListener('mouseover', () => {
        mutateToolbar.visible = true
      })

      imageFlipBuffer.element.addEventListener('mouseout', () => {
        mutateToolbar.visible = false
      })
    }

    return imageFlipBuffer
  }

  removeAllBuffers () {
    for (let index = 0; index < this.variationsBuffers.length; index++) {
      this.variationsBuffers[index].remove()
    }

    this.variationsBuffers = []
  }

  async drawNewPopulation (): Promise<void> {
    const { drawing } = this.props
    this.lastChosen = 0
    this.drawings[this.lastChosen] = drawing
    this.firstTime = true
    this.breedCounter = random(24) + 12

    this.mutateChildren()
    await this.draw()
  }

  randomDifferentIndex (): number {
    const { count } = this.props.gridSizeUnits
    const bag: number[] = [...Array(count).keys()].filter(index => index !== this.lastChosen)
    return sample(bag) as number
  }

  async choose (index: number): Promise<void> {
    this.updateCursor()
    this.variationsBuffers[index].highlight()
    await sleep(800)
    this.lastChosen = index
    this.mutateChildren()
    await this.draw()
  }

  mutateChildren (): void {
    const { count } = this.props.gridSizeUnits
    for (let i = 0; i < count; i++) {
      if (i === this.lastChosen) continue
      const child = new CGPGrid(this.drawings[this.lastChosen])
      child.mutate(random(3) + 2)
      this.drawings[i] = child
    }
  }

  async drawBuffer (buffer: ImageFlipBuffer, drawing: CGPGrid): Promise<void> {
    buffer.highlight()
    buffer.start()
    const parser = new Parser(drawing, buffer.svg, this.props.drawingSize)

    await sleep(200)
    await parser.draw()

    buffer.flip()
    await sleep(300)
    buffer.highlight('rgba(0,0,0,0)')
  }

  async draw (): Promise<void> {
    const { count } = this.props.gridSizeUnits
    this.busyAutobreeding = true
    Toolbar.allEnabled = false
    this.updateCursor()
    let tooSlow = false

    for (let i = 0; i < count; i++) {
      if (i === this.lastChosen && !this.firstTime) continue
      const { duration } = await measureDuration<void>(
        async () => this.drawBuffer(this.variationsBuffers[i], this.drawings[i]),
      )

      if (duration > 2000) {
        tooSlow = true // eslint-disable-line
      }
    }

    this.firstTime = false
    if (!isInteractive) {
      await sleep(1600)
      this.breedCounter--
    } else {
      await sleep(400)
      this.unhighlightLastChosen()
    }

    this.busyAutobreeding = false
    Toolbar.allEnabled = true
    this.updateCursor()

    if (!isInteractive) await this.judge(tooSlow)
  }

  async judge (tooSlow: boolean): Promise<void> {
    const f = await fitnessFunction(this.variationsBuffers)

    if (this.breedCounter <= 0 || tooSlow) {
      await this.showLarge(this.lastChosen)
      this.unhighlightLastChosen()
      return this.restart()
    }

    this.unhighlightLastChosen()

    if (this.previousLargestComplexity === f.largestComplexity) {
      await this.showLarge(this.lastChosen)
      return this.restart()
    } else {
      this.previousLargestComplexity = f.largestComplexity
    }

    if (f.largestComplexity < EMPTY_WORK_COMPLEXITY) {
      return this.restart()
    }

    return this.choose(f.largestIndex)
  }

  async showLarge (index: number): Promise<void> { // eslint-disable-line
    // if (this.containerElement === null) throw new Error('should not happen')
    // const v: Viewport = viewport()
    // const smallest = Math.min(v.width, v.height)
    // const size = { width: smallest, height: smallest }
    // const buffer = new ImageFlipBuffer(this.containerElement, size)

    // buffer.x = (v.width - smallest) / 2
    // buffer.y = (v.height - smallest) / 2
    // buffer.start()

    // const parser = new Parser(this.drawings[index], buffer.svg, size)
    // await parser.draw()
    // buffer.flip()
    // await sleep(4000)
    // await ImageFlipBuffer.fadeOutAll()

    // buffer.svgInstances.forEach(instance => {
    //   if (this.containerElement === null) throw new Error('should not happen')
    //   this.containerElement.removeChild(instance.node)
    // })
  }

  unhighlightLastChosen (): void {
    this.variationsBuffers[this.lastChosen].highlight('rgba(0,0,0,0)')
  }

  async restart (): Promise<void> {
    this.previousLargestComplexity = 0
    await ImageFlipBuffer.fadeOutAll()
    await this.drawNewPopulation()
  }

  updateCursor (): void {
    if (this.containerElement === null) return
    this.containerElement.style.cursor = isInteractive
      ? this.busyAutobreeding
        ? 'wait'
        : 'pointer'
      : 'none'
  }

  initMutatorScreen = (containerElement: HTMLDivElement): void => {
    if (containerElement === undefined || this.containerElement !== null) return
    const { count } = this.props.gridSizeUnits
    this.containerElement = containerElement
    for (let index = 0; index < count; index++) {
      this.variationsBuffers[index] = this.createFlipBuffer(index)
    }

    this.drawNewPopulation().then(NOOP, NOOP)
  }

  componentWillUnmount () {
    this.removeAllBuffers()
  }

  render () {
    this.updateCursor()
    return (
      <div
        id='drawings'
        style={{ display: this.props.visible ? 'block' : 'none' }}
        ref={this.initMutatorScreen}
      />
    )
  }
}
