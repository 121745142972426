import { CGPNextNodes, CGPVariableAddresses } from './CGP'
import { CommandId } from './commands'

export default class CGPNode {
  public command: CommandId
  public next: CGPNextNodes
  public variables: CGPVariableAddresses
  public touched = false
  public index: number
  constructor (index: number, command: CommandId, next: CGPNextNodes, variables: CGPVariableAddresses) {
    this.index = index
    this.command = command
    this.next = next
    this.variables = variables
  }
}
