import { viewport } from './utils'
import { BORDER_WIDTH } from './const'

export const TOP_MARGE = 48

export interface GridSizeUnits {
  columns: number
  rows: number
  count: number
}

const MAX_COLS = 4
const MAX_ROWS = 4

export function calculateGridSizeUnits (): GridSizeUnits {
  const screen = viewport()
  const v = {
    width: screen.width,
    height: screen.height - TOP_MARGE,
  }
  const size = 280
  const columns = Math.round(v.width / size + 0.2)
  const rows = Math.round(v.height / size + 0.2)
  const ratio = v.width / v.height

  interface ColumnsRow {
    columns: number
    rows: number
  }

  const withCount = (dims: ColumnsRow): GridSizeUnits => ({ ...dims, count: dims.columns * dims.rows })

  if (columns === 1 && rows === 2) {
    return withCount({
      columns: 1,
      rows: 3,
    })
  }

  if (columns === 2 && rows === 1) {
    return withCount({
      columns: 3,
      rows: 1,
    })
  }

  if (columns + rows === 2) {
    if (ratio > 0.9 && ratio < 1.25) {
      return withCount({
        columns: 2,
        rows: 2,
      })
    }
    return withCount({
      columns: 1,
      rows: 3,
    })
  }

  if (columns >= MAX_COLS) {
    return withCount({
      columns: MAX_COLS,
      rows: Math.round(MAX_COLS / ratio + 0.2),
    })
  }

  if (rows >= MAX_ROWS) {
    return withCount({
      columns: Math.round(MAX_ROWS * ratio + 0.2),
      rows: MAX_ROWS,
    })
  }

  return withCount({
    columns,
    rows,
  })
}

export function calculateDrawingsize (gridSizeUnits: GridSizeUnits) {
  const screen = viewport()
  const v = {
    width: screen.width,
    height: screen.height - TOP_MARGE,
  }
  const { rows, columns } = gridSizeUnits
  // const smallest = Math.min(this.v.width, this.v.height) / rows - BORDER_WIDTH * 2
  return {
    width: (v.width / columns << 0) - BORDER_WIDTH * 2,
    height: (v.height / rows << 0) - BORDER_WIDTH * 2,
  }
}
