import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import Provider from './Provider'

const component = <Provider />

let throttleId: any = null // eslint-disable-line

window.addEventListener('resize', () => {
  if (throttleId) {
    clearTimeout(throttleId)
  }
  throttleId = setTimeout(() => {location.href = location.href}, 250) // eslint-disable-line
})

ReactDOM.render(component, document.getElementById('root'))
