import { cloneDeep, sample, random } from 'lodash'
import { initArray } from './utils'
import { Palette, randomColorArray } from './color'
import CGPNode from './CGPNode'
import * as Commands from './constants/commands'
import { List, weightedCommands, CommandId } from './commands'

export type CGPNodeAddress = number
export type CGPVariableAddress = number
export type VariableValue = number

export const TERMINATOR_NODE_ADDRESS = -1

const NEXT_LAYER_JUMP_ONLY = true

interface CGPDimensions {
  layers: number
  layerSize: number
  memory: number
  colors: number
}

export const MIN_DIMENSIONS: CGPDimensions = {
  layers: 16,
  layerSize: 16,
  memory: 16,
  colors: 4,
}

export const MAX_DIMENSIONS: CGPDimensions = {
  layers: 32,
  layerSize: 32,
  memory: 128,
  colors: 16,
}

export const MEMORY_VALUE_MAX = 255

export type CGPNextNodes = [CGPNodeAddress, CGPNodeAddress, CGPNodeAddress]
export type CGPVariableAddresses = [CGPVariableAddress, CGPVariableAddress, CGPVariableAddress]
export type VariableValues = [VariableValue, VariableValue, VariableValue]

export type Memory = VariableValue[]

const randomVariableAddress = (): CGPVariableAddress => random(MAX_DIMENSIONS.memory - 1)

const randomPalette = (): Palette => initArray(MAX_DIMENSIONS.colors, randomColorArray)

const randomNodeIndex = (layer: number): number => {
  const firstNode = (layer + 1) * MAX_DIMENSIONS.layerSize
  if (NEXT_LAYER_JUMP_ONLY) {
    const result = Math.floor(Math.random() * MAX_DIMENSIONS.layerSize) + firstNode
    if (result - firstNode > MAX_DIMENSIONS.layerSize) {
      console.error('exceeds!', firstNode, result)
    }
    return result
  } else {
    const totalNodes = MAX_DIMENSIONS.layerSize * MAX_DIMENSIONS.layers
    return Math.floor(Math.random() * (totalNodes - firstNode)) + firstNode
  }
}

function createRandomNode (index: number, layer: number, commands: List): CGPNode {
  const last = layer === MAX_DIMENSIONS.layers - 1
  const randomNext = (): CGPNodeAddress => last ? TERMINATOR_NODE_ADDRESS : randomNodeIndex(layer)

  const command = last ? Commands.TERMINATE : sample(commands) /// weightedCommands)
  if (command === undefined) throw new Error('this should not happen')
  return new CGPNode(
    index,
    command,
    [
      randomNext(),
      randomNext(),
      randomNext(),
    ],
    [
      randomVariableAddress(),
      randomVariableAddress(),
      randomVariableAddress(),
    ],
  )
}

function between (min: number, max: number) {
  return Math.round(Math.random() * (max - min) + min)
}

function createRandomMemory (): VariableValue[] {
  const result = []
  for (let i = 0; i < MAX_DIMENSIONS.memory; i++) {
    result[i] = random(MEMORY_VALUE_MAX)
  }
  return result
}

function mutateStep (nodes: CGPNode[], commands: List, memory: Memory, palette: Palette): void {
  const touchedNodes = nodes.filter(node => node.touched && node.command !== Commands.TERMINATE)

  const useNodes = touchedNodes.length === 0 ? nodes : touchedNodes
  const nodeIndex = Math.round(Math.random() * (useNodes.length - 1))
  const node = useNodes[nodeIndex]
  const mutationKind = random(6)

  switch (mutationKind) {
    case 0:
    case 1:
      node.command = sample(commands.filter(command => command !== node.command)) as CommandId
      break

    case 2:
    case 3: {
      const newNodeAddess = randomNodeIndex(Math.floor(node.index / MAX_DIMENSIONS.layerSize))
      node.next[random(2)] = newNodeAddess
      break
    }

    case 4:
      node.variables[random(2)] = randomVariableAddress()
      break

    case 5: {
      const address = node.variables[random(2)]
      memory[address] = random(MEMORY_VALUE_MAX)
      break
    }

    case 6: {
      const paletteIndex = random(palette.length - 1)
      palette[paletteIndex][random(2)] = random(MAX_DIMENSIONS.memory)
      break
    }

    default:
      alert('unknown mutation kind')
  }
}

export default class CGPGrid {
  nodes: CGPNode[] = []
  commands: List = weightedCommands()
  memory: Memory
  palette: Palette
  private readonly dimensions: CGPDimensions = MAX_DIMENSIONS

  mutate (count: number): void {
    for (let i = 0; i < count; i++) {
      try {
        mutateStep(this.nodes, this.commands, this.memory, this.palette)
      } catch (error) {
        console.error(error)
      }
    }
  }

  constructor (networkData: CGPGrid | null = null) {
    if (networkData !== null) { // copy constructor
      const { nodes, memory, palette } = cloneDeep(networkData)
      this.nodes = nodes
      this.memory = memory
      this.palette = palette
    } else {
      let index = 0

      this.dimensions.layers = between(MIN_DIMENSIONS.layers, MAX_DIMENSIONS.layers)
      this.dimensions.layerSize = between(MIN_DIMENSIONS.layerSize, MAX_DIMENSIONS.layerSize)
      // Math.max(MIN_DIMENSIONS.layerSize, between(
      //   MIN_DIMENSIONS.layerSize,
      //   MAX_DIMENSIONS.layerSize - (this.dimensions.layers - 1),
      // ))
      this.dimensions.colors = between(MIN_DIMENSIONS.colors, MAX_DIMENSIONS.colors)
      this.dimensions.memory = between(MIN_DIMENSIONS.memory, MAX_DIMENSIONS.memory)
      for (let layer = 0; layer < MAX_DIMENSIONS.layers; layer++) {
        for (let i = 0; i < MAX_DIMENSIONS.layerSize; i++) {
          this.nodes[i + layer * MAX_DIMENSIONS.layerSize] = createRandomNode(index, layer, this.commands)
          index++
        }
      }

      this.memory = createRandomMemory()
      this.palette = randomPalette()
    }
  }
}
