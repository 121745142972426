export default class MutateToolbar {
  private static readonly instances: MutateToolbar[] = []
  public onClickRemember: Function = (): void => {} // eslint-disable-line
  public onClickMutate: Function = (): void => {} // eslint-disable-line
  public readonly element: HTMLDivElement
  private readonly saveButton: HTMLButtonElement
  private readonly mutateButton: HTMLButtonElement
  private isEnabled = true
  private isVisible = false

  constructor (parentElement: HTMLElement) {
    this.element = document.createElement('div')
    this.element.className = 'toolbar'

    this.saveButton = document.createElement('button')
    this.saveButton.className = 'button'
    this.saveButton.innerHTML = 'to garden'

    this.saveButton.addEventListener('mousedown', e => {
      e.stopPropagation()
      this.onClickRemember()
    })

    this.mutateButton = document.createElement('button')
    this.mutateButton.className = 'button'
    this.mutateButton.innerHTML = 'mutate'

    this.mutateButton.addEventListener('mousedown', e => {
      e.stopPropagation()
      this.onClickMutate()
    })

    this.element.appendChild(this.mutateButton)
    this.element.appendChild(this.saveButton)

    this.element.className = 'toolbar'

    parentElement.appendChild(this.element)
    MutateToolbar.instances.push(this)
  }

  set visible (value: boolean) {
    this.isVisible = value
    this.update()
  }

  set enabled (value: boolean) {
    this.isEnabled = value
    this.update()
  }

  static set allEnabled (value: boolean) {
    MutateToolbar.instances.forEach((instance: MutateToolbar) => {
      instance.enabled = value
    })
  }

  update (): void {
    this.element.style.opacity = (this.isEnabled && this.isVisible)
      ? '1'
      : '0'
  }
}
