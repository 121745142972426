import { random } from 'lodash'
import * as CommandTypes from './constants/command-types'
import * as Commands from './constants/commands'

export type CommandId =
  CommandTypes.Terminate |
  CommandTypes.Split |
  CommandTypes.Margin |
  CommandTypes.Fill |
  CommandTypes.Crook |
  CommandTypes.Circle |
  CommandTypes.For |
  CommandTypes.Multiply |
  CommandTypes.Stroke |
  CommandTypes.Increment1 |
  CommandTypes.Decrement1 |
  CommandTypes.Increment2 |
  CommandTypes.Decrement2 |
  CommandTypes.Increment3 |
  CommandTypes.Decrement3 |
  CommandTypes.SamePlace |
  CommandTypes.TerminateWhen |
  CommandTypes.Triangle |
  CommandTypes.Switch |
  CommandTypes.DottedLine |
  CommandTypes.Square |
  CommandTypes.AB |
  CommandTypes.Shift |
  CommandTypes.Window

export const availableCommands: List = [
  Commands.AB,
  Commands.FOR,
  Commands.FILL,
  Commands.SPLIT,
  Commands.SHIFT,
  Commands.CROOK,
  Commands.MARGIN,
  Commands.STROKE,
  Commands.SWITCH,
  Commands.SQUARE,
  Commands.WINDOW,
  Commands.CIRCLE,
  Commands.LINES,
  Commands.MULTIPLY,
  Commands.TRIANGLE,
  Commands.SAME_PLACE,
  Commands.TERMINATE_WHEN,
  Commands.INCREMENT_REGISTER_1,
  Commands.INCREMENT_REGISTER_2,
  Commands.INCREMENT_REGISTER_3,
  Commands.DECREMENT_REGISTER_1,
  Commands.DECREMENT_REGISTER_2,
  Commands.DECREMENT_REGISTER_3,
]

export type List = CommandId[]

export function weightedCommands (): List {
  const chances = {
    [Commands.AB]: random(30) * random(1),
    [Commands.FOR]: random(50) * random(1),
    [Commands.FILL]: random(50) * random(1),
    [Commands.SHIFT]: random(50) * random(1),
    [Commands.SPLIT]: random(50) * random(1),
    [Commands.CROOK]: random(50) * random(1),
    [Commands.LINES]: random(30) * random(1),
    [Commands.MARGIN]: random(50) * random(1),
    [Commands.STROKE]: random(50) * random(1),
    [Commands.SWITCH]: random(50) * random(1),
    [Commands.CIRCLE]: random(20) * random(1),
    [Commands.SQUARE]: random(50) * random(1),
    [Commands.WINDOW]: random(3) * random(1),
    [Commands.MULTIPLY]: random(10),
    [Commands.TRIANGLE]: random(2) * random(1),
    [Commands.SAME_PLACE]: random(30) * random(1),
    [Commands.TERMINATE_WHEN]: random(20) * random(1),
    [Commands.INCREMENT_REGISTER_1]: random(50) * random(1),
    [Commands.DECREMENT_REGISTER_1]: random(50) * random(1),
    [Commands.INCREMENT_REGISTER_2]: random(50) * random(1),
    [Commands.DECREMENT_REGISTER_2]: random(50) * random(1),
    [Commands.INCREMENT_REGISTER_3]: random(50) * random(1),
    [Commands.DECREMENT_REGISTER_3]: random(50) * random(1),
  }

  return availableCommands.reduce((result: List, current: CommandId): List => {
    const frequency: number = chances[current]
    for (let i = 0; i < frequency; i++) {
      result.push(current)
    }
    return result
  }, [])
}
