import React, { Component } from 'react'
import Parser from './Parser'
import { Viewport, NOOP, isTouchDevice } from './utils'
import { GridSizeUnits } from './gridSize'
import FavToolbar from './FavToolbar'
import FavToolbarMobile from './FavToolbarMobile'
import CGPGrid from './CGP'
import ImageFlipBuffer from './ImageFlipBuffer'

const Toolbar = isTouchDevice ? FavToolbarMobile : FavToolbar

interface Props {
  visible: boolean
  drawings: CGPGrid[]
  onClickDrawing: Function
  gridSizeUnits: GridSizeUnits
  drawingSize: Viewport
  onClickRemove: Function
  onClickMutate: Function
  onClickAdopt: Function
}

export default class FavsScreen extends Component<Props> {
  private variationsBuffers: ImageFlipBuffer[] = []
  private containerElement: HTMLDivElement | null = null

  onClickDrawing = (index: number): void => {
    this.props.onClickDrawing(index)
  }

  createFlipBuffer (index: number): ImageFlipBuffer {
    if (this.containerElement === null) throw new Error('Parent container not initialised')
    const {
      drawingSize,
      gridSizeUnits,
      onClickRemove,
      onClickMutate,
      onClickAdopt,
    } = this.props
    const imageFlipBuffer = ImageFlipBuffer.factory(this.containerElement, index, drawingSize, gridSizeUnits)
    const favToolbar = new Toolbar(imageFlipBuffer.element)

    favToolbar.onClickMutate = () => onClickMutate(index)
    favToolbar.onClickAdopt = () => {
      onClickAdopt(index, this.variationsBuffers[index].visibleSVG)
    }
    favToolbar.onClickRemove = () => onClickRemove(index)

    if (!isTouchDevice) {
      imageFlipBuffer.onClick = () => this.onClickDrawing(index)

      imageFlipBuffer.element.addEventListener('mouseover', () => {
        favToolbar.visible = true
      })

      imageFlipBuffer.element.addEventListener('mouseout', () => {
        favToolbar.visible = false
      })
    }

    return imageFlipBuffer
  }

  drawBuffer (buffer: ImageFlipBuffer, drawing: CGPGrid): void {
    buffer.start()
    const parser = new Parser(drawing, buffer.svg, this.props.drawingSize)
    parser.draw().then(NOOP, NOOP)
    buffer.flip()
  }

  removeAllBuffers () {
    for (let index = 0; index < this.variationsBuffers.length; index++) {
      this.variationsBuffers[index].remove()
    }

    this.variationsBuffers = []
  }

  componentWillUnmount () {
    this.removeAllBuffers()
  }

  draw (): void {
    if (this.containerElement === null) return
    const { drawings, gridSizeUnits } = this.props

    this.removeAllBuffers()

    const minimumCount = Math.min(gridSizeUnits.count, drawings.length)
    for (let index = 0; index < minimumCount; index++) {
      this.variationsBuffers[index] = this.createFlipBuffer(index)
    }

    FavToolbar.allEnabled = false

    for (let i = 0; i < minimumCount; i++) {
      this.drawBuffer(this.variationsBuffers[i], drawings[i])
    }

    FavToolbar.allEnabled = true
  }

  initFavsScreen = (containerElement: HTMLDivElement): void => {
    if (containerElement === undefined || this.containerElement !== null) return
    this.containerElement = containerElement
    this.draw()
  }

  shouldComponentUpdate (nextProps: Props) {
    if (this.containerElement !== null) {
      this.containerElement.style.display = nextProps.visible ? 'block' : 'none'
    }
    return nextProps.drawings !== this.props.drawings
  }

  render () {
    this.draw()
    return (
      <div
        id='favs'
        ref={this.initFavsScreen}
        style={{ display: this.props.visible ? 'block' : 'none' }}
      />
    )
  }
}
