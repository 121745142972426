import {
  Color,
  CHANNEL_ALPHA,
  toCSSRGB,
  dimmedGradient,
  GradientType,
} from './color'
import { MEMORY_VALUE_MAX } from './CGP'
import { State } from './Parser'
import { SVGInstance } from './ImageFlipBuffer'
import { BB } from './utils'

export const MARGIN_LINE = 3
export const MARGIN_SPACE = 20

const STROKE_WIDTH = 0.5

export function rect (
  state: State,
  fillColor: Color | null,
  strokeColor: Color | null,
  svg: SVGInstance,
  rounding: number | null,
  gradientDirection: boolean,
  gradientDimFactor: number,
  gradientType: GradientType,
): void {
  const { bb } = state
  const smallestDimension = smallestDim(bb)
  if (smallestDimension < 2) return
  const margin = bb[2] - bb[0] > MARGIN_LINE * 2 && bb[3] - bb[1] > MARGIN_LINE * 2
    ? MARGIN_LINE : 0

  const rectangle = svg.rect(
    (bb[2] - bb[0] - margin * 2).toFixed(2),
    (bb[3] - bb[1] - margin * 2).toFixed(2),
  ).move(
    (bb[0] + margin).toFixed(2),
    (bb[1] + margin).toFixed(2),
  ).attr({
    fill: fillColor ? dimmedGradient(fillColor, svg, gradientDirection, gradientDimFactor, gradientType) : null,
    stroke: strokeColor ? toCSSRGB(strokeColor) : null,
    'stroke-width': strokeColor ? STROKE_WIDTH : null,
    'fill-opacity': fillColor ? (fillColor[CHANNEL_ALPHA] / MEMORY_VALUE_MAX).toFixed(2) : 0,
    // style: 'mix-blend-mode: lighten',
  })

  if (rounding) {
    const roundingNormalized = rounding / MEMORY_VALUE_MAX * smallestDimension / 2
    rectangle.radius(roundingNormalized)
  }
}

export function rhomb (
  bb: BB,
  color: Color,
  svg: SVGInstance,
  gradientDirection: boolean,
  strokeOnly: boolean,
  gradientDimFactor: number,
  gradientType: GradientType,
): BB {
  const midX = (bb[2] - bb[0]) / 2 + bb[0]
  const midY = (bb[3] - bb[1]) / 2 + bb[1]
  const polygon = svg.polygon([
    [midX, bb[1]],
    [bb[2], midY],
    [midX, bb[3]],
    [bb[0], midY],
  ])
  polygon.attr({
    fill: strokeOnly ? null : dimmedGradient(color, svg, gradientDirection, gradientDimFactor, gradientType),
    stroke: toCSSRGB(color),
    'fill-opacity': strokeOnly ? 0 : (Math.max(0.1, color[CHANNEL_ALPHA] / MEMORY_VALUE_MAX)).toFixed(2),
  })

  return [
    (bb[2] - bb[0]) / 4 + bb[0],
    (bb[3] - bb[1]) / 4 + bb[1],
    (bb[2] - bb[0]) / 4 * 3 + bb[0],
    (bb[3] - bb[1]) / 4 * 3 + bb[1],
  ]
}

export function triangle (
  state: State,
  fillColor: Color,
  svg: SVGInstance,
  gradientDirection: boolean,
  shapeDirection: boolean,
  gradientDimFactor: number,
  gradientType: GradientType,
): void {
  const { bb } = state
  const smallestDimension = smallestDim(bb)
  if (smallestDimension < 1) return

  const polygon = svg.polygon(
    shapeDirection ? [
      [bb[0], bb[1]],
      [bb[2], bb[3]],
      [bb[0], bb[3]],
    ] : [
      [bb[0], bb[1]],
      [bb[2], bb[3]],
      [bb[2], bb[1]],
    ],
  )

  polygon.attr({
    fill: fillColor ? dimmedGradient(fillColor, svg, gradientDirection, gradientDimFactor, gradientType) : null,
  })
}

export const smallestDim = (bb: BB) => Math.min(bb[2] - bb[0], bb[3] - bb[1])

export function circle (
  state: State,
  color: Color,
  svg: SVGInstance,
  showAsSquare: boolean,
  gradientDirection: boolean,
  gradientDimFactor: number,
  gradientType: GradientType,
): void {
  const { bb } = state
  const smallestDimension = smallestDim(bb)
  if (smallestDimension < 1) return
  const margin = bb[2] - bb[0] > MARGIN_LINE * 2 && bb[3] - bb[1] > MARGIN_LINE * 2
    ? MARGIN_LINE : 0

  const dim = smallestDimension - margin * 2
  const object = showAsSquare
    ? svg.rect(dim, dim)
    : svg.circle(dim)

  object.move(
    bb[0] + margin + (bb[2] - bb[0] - smallestDimension) / 2,
    bb[1] + margin + (bb[3] - bb[1] - smallestDimension) / 2,
  ).attr({
    fill: dimmedGradient(color, svg, gradientDirection, gradientDimFactor, gradientType),
    'fill-opacity': (color[CHANNEL_ALPHA] / MEMORY_VALUE_MAX).toFixed(2),
  })
}
