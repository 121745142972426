import CGPGrid from './CGP'

const LOCALSTORAGE_KEY = 'cgp-harmvandendorpel'

interface Data {
  drawings: CGPGrid[]
  index: number | null
}

export default class Store {
  private data: Data = {
    drawings: [],
    index: null,
  }

  public onChange: Function = () => {} // eslint-disable-line

  private persist (): void {
    const dataAsString = JSON.stringify(this.data)
    window.localStorage.setItem(LOCALSTORAGE_KEY, dataAsString)
    this.onChange()
  }

  private read (): void {
    const dataAsString = window.localStorage.getItem(LOCALSTORAGE_KEY)
    if (dataAsString) {
      this.data = JSON.parse(dataAsString)
    }
  }

  addDrawing (drawing: CGPGrid): void {
    this.data.drawings = [...this.data.drawings, drawing]
    this.data.index = this.data.drawings.length - 1
    this.persist()
  }

  removeDrawing (index: number): void {
    this.data.drawings.splice(index, 1)
    this.data.drawings = [...this.data.drawings]
    this.persist()
  }

  get favs (): CGPGrid[] {
    return this.data.drawings
  }

  get currentDrawing (): CGPGrid | null {
    if (this.data.index === null) return null
    return this.data.drawings[this.data.index]
  }

  constructor () {
    this.read()
  }
}
