import { svgAsPngUri } from 'save-svg-as-png'
import SVG from 'svg.js'
import ImageFlipBuffer from './ImageFlipBuffer'
import Parser from './Parser'
import CGPGrid from './CGP'
import { Viewport, measureDuration } from './utils'
import { MINIMUM_FILE_SIZE } from './const'

interface FitnessResult {
  largestComplexity: number
  largestIndex: number
  // imageData: string
}

export async function fitnessFunction (variationsBuffers: ImageFlipBuffer[]): Promise<FitnessResult> {
  const promises: Array<Promise<string>> = variationsBuffers.map((_, index) =>
    asPng(variationsBuffers[index]))

  const result = await Promise.all(promises)
  let largestIndex = 0
  let largestComplexity = 0

  for (let i = 0; i < result.length; i++) {
    const complexity = result[i].length

    if (complexity > largestComplexity) {
      largestIndex = i
      largestComplexity = complexity
    }
  }

  return { largestComplexity, largestIndex } //, imageData }
}

const asPng = (buffer: ImageFlipBuffer) => svgAsPngUri(buffer.visibleSVG.node)

export async function findSuitableRandomDrawing (drawingSize: Viewport): Promise<CGPGrid> {
  const hiddenParentElement = document.createElement('div')
  window.document.body.appendChild(hiddenParentElement)
  if (hiddenParentElement === null) throw new Error('Cannot find hidden DOM element')

  while (true) {
    const newRandomDrawing = new CGPGrid()
    const svg = SVG(hiddenParentElement)
    const parser = new Parser(newRandomDrawing, svg, drawingSize)
    const { duration } = await measureDuration(async () => parser.draw())

    const pngData = await svgAsPngUri(svg.node)

    if (pngData.length > MINIMUM_FILE_SIZE && duration < 1500) {
      return newRandomDrawing
    }
  }
}
