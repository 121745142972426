export type BB = [
  number, number,
  number,
  number
] // bounding box

export interface Viewport {
  width: number
  height: number
}

export const sleep = async (delay: number): Promise<void> =>
  new Promise(resolve => setTimeout(resolve, delay))

export function viewport (): Viewport {
  const ratio = 1 // window.devicePixelRatio,
  const { clientWidth, clientHeight } = window.document.documentElement
  return {
    width: clientWidth * ratio,
    height: clientHeight * ratio,
  }
}

export const initArray = (size: number, func: (item: any, index: number) => any) => // eslint-disable-line
  Array.apply(null, Array(size)).map(func) // eslint-disable-line

export const bound = (value: number, min: number, max: number): number => Math.min(Math.max(value, min), max)
export const viewportToBoundingBox = (viewport: Viewport): BB => [0, 0, viewport.width, viewport.height]
export const now = () => new Date().getTime()
export const NOOP: (value: void) => void | PromiseLike<void> = () => {} // eslint-disable-line

interface DurationResult<T> {
  result: T
  duration: number
}

export async function measureDuration<T> (f: () => T): Promise<DurationResult<T>> {
  const startTime = now()
  const result = await f()
  return {
    result,
    duration: now() - startTime,
  }
}

export function px (pixels: number) {
  return `${pixels.toString()}px`
}

export const isTouchDevice = ('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0)
