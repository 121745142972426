import { cloneDeep } from 'lodash'
import { Viewport, bound, viewportToBoundingBox, BB } from './utils'
import { SVGInstance } from './ImageFlipBuffer'
import CGPGrid, {
  CGPNodeAddress,
  CGPVariableAddresses,
  CGPVariableAddress,
  VariableValues,
  VariableValue,
  MEMORY_VALUE_MAX,
  Memory,
} from './CGP'
import * as Commands from './constants/commands'
import CGPNode from './CGPNode'
import { Color, Channel, ChannelAddress, toCSSRGB, Palette, GradientType } from './color'
import { rect, circle, triangle, MARGIN_SPACE, MARGIN_LINE, rhomb, smallestDim } from './canvas'

export interface State { // basically used by a stack
  bb: BB
}

type DirectionHorizontal = string
type DirectionVertical = string
type Direction = DirectionHorizontal | DirectionVertical

const VERTICAL: DirectionVertical = 'vertical'
const HORIZONTAL: DirectionHorizontal = 'horizontal'

const value = (variables: VariableValues, index: number, max: number): number =>
  Math.round(variables[index] / MEMORY_VALUE_MAX * max)

export default class Parser {
  private aborted = false
  private readonly svg: SVGInstance
  private readonly grid: CGPGrid
  private readonly onBeat: Function = () => {} // eslint-disable-line
  private memory: Memory
  private readonly palette: Palette
  private readonly drawingSize: Viewport

  constructor (
    grid: CGPGrid,
    svg: SVGInstance,
    drawingSize: Viewport,
  ) {
    this.grid = grid
    this.svg = svg
    this.memory = cloneDeep(this.grid.memory)
    this.palette = cloneDeep(this.grid.palette)
    this.drawingSize = drawingSize
  }

  lookupValues (variables: CGPVariableAddresses): VariableValues {
    return variables.map((address: CGPVariableAddress): VariableValue => this.memory[address]) as VariableValues
  }

  incrementMemory (node: CGPNode, amount: number, registerIndex: number) {
    const oldValue = this.memory[node.variables[registerIndex]]
    const newValue = oldValue + amount * 2
    this.memory[node.variables[registerIndex]] = bound(newValue, 0, MEMORY_VALUE_MAX)
  }

  currentColor (node: CGPNode): Color {
    const paletteIndex = Math.round(this.memory[node.variables[0]] / MEMORY_VALUE_MAX * (this.palette.length - 1))
    const colorPointer = this.palette[paletteIndex]
    const result = colorPointer.map((channelAddress: ChannelAddress): Channel => this.memory[channelAddress] / MEMORY_VALUE_MAX * 255)
    return result as Color
  }

  async decodeSplit (index: CGPNodeAddress, variables: VariableValues, state: State, node: CGPNode, takeFirst: boolean): Promise<void> {
    let direction: Direction | null = null
    // if (variables[0] < MEMORY_VALUE_MAX / 4) {
    //   direction = HORIZONTAL
    // } else if (variables[0] < MEMORY_VALUE_MAX / 4 * 2) {
    //   direction = VERTICAL
    // } else {
    direction = state.bb[3] - state.bb[1] > state.bb[2] - state.bb[0]
      ? VERTICAL : HORIZONTAL
    // }
    const count = value(variables, 1, 2) + 1
    const continueIndex = value(variables, 2, 3)
    const traverseOrder = variables[1] > MEMORY_VALUE_MAX / 2
    await this.split(index, direction, count, state, node, takeFirst, continueIndex, traverseOrder)
  }

  async split (
    parentIndex: CGPNodeAddress,
    direction: Direction,
    count: number,
    state: State,
    node: CGPNode,
    takeFirst: boolean,
    continueIndex: number | null,
    traverseOrder: boolean,
  ) {
    const size = (direction === HORIZONTAL)
      ? (state.bb[2] - state.bb[0]) / count
      : (state.bb[3] - state.bb[1]) / count

    for (let i = 0; i < count; i++) {
      const index = traverseOrder ? i : count - i - 1
      const bb: BB = (direction === HORIZONTAL) ? [
        state.bb[0] + size * index,
        state.bb[1],
        state.bb[0] + size * (index + 1),
        state.bb[3],
      ]
        : [
          state.bb[0],
          state.bb[1] + size * index,
          state.bb[2],
          state.bb[1] + size * (index + 1),
        ]

      const newState: State = { ...state, bb }

      if (continueIndex !== null && index === continueIndex) {
        await this.drawNode(node.next[1], newState)
      } else {
        const next = node.next[takeFirst ? 0 : index]
        await this.drawNode(next, newState)
      }
    }
  }

  async drawWindow (
    parentIndex: CGPNodeAddress,
    state: State,
    node: CGPNode,
    takeCenter: boolean,
    gradientDirection: boolean,
    strokeOnly: boolean,
    gradientDimFactor: number,
    gradientType: GradientType,
  ) {
    const centerBB: BB = rhomb(
      state.bb,
      this.currentColor(node), // , this.grid),
      this.svg,
      gradientDirection,
      strokeOnly,
      gradientDimFactor,
      gradientType,
    )
    const nextState: State = { ...state, bb: takeCenter ? centerBB : state.bb }
    const next = node.next[0]
    await this.drawNode(next, nextState)
  }

  drawLines (state: State, color: Color, variables: VariableValues) {
    const { bb } = state
    const smallestDimension = smallestDim(bb)
    if (smallestDimension < 4) return
    const MAX_DISTANCE = 25
    const lineDistance = variables[1] / MEMORY_VALUE_MAX * MAX_DISTANCE << 0
    const direction: Direction = variables[2] > variables[0] ? HORIZONTAL : VERTICAL

    const count = direction === VERTICAL
      ? (bb[3] - bb[1]) / lineDistance << 0
      : (bb[2] - bb[0]) / lineDistance << 0

    for (let i = 0; i < count; i++) {
      let line = null
      if (direction === VERTICAL) {
        const y = bb[1] + (i + 0.5) * lineDistance
        line = this.svg.line(
          bb[0],
          y,
          bb[2],
          y,
        )
      } else {
        const x = bb[0] + (i + 0.5) * lineDistance
        line = this.svg.line(
          x,
          bb[1],
          x,
          bb[3],
        )
      }

      line
        .stroke({ width: 0.1 })
        .attr({ stroke: toCSSRGB(color) })
    }
  }

  async margin (index: CGPNodeAddress, margin: number, node: CGPNode, state: State): Promise<void> {
    const nextState: State = (
      state.bb[0] + margin < state.bb[2] - margin &&
      state.bb[1] + margin < state.bb[3] - margin
    )
      ? {
        ...state,
        bb: [
          state.bb[0] + margin,
          state.bb[1] + margin,
          state.bb[2] - margin,
          state.bb[3] - margin,
        ],
      }
      : state
    const next = node.next[0]
    await this.drawNode(next, nextState)
  }

  async drawNode (index: CGPNodeAddress, state: State): Promise<void> {
    if (this.aborted) {
      return
    }

    const { nodes } = this.grid
    const node = nodes[index]
    const { bb } = state
    if (smallestDim(bb) < 2) return
    if (node === undefined) return
    node.touched = true
    const { next, command } = node
    const variables = this.lookupValues(node.variables)

    switch (command) {
      case Commands.TERMINATE:
        // do nothing
        break

      case Commands.SAME_PLACE:
        await this.drawNode(next[0], state)
        await this.drawNode(next[1], state)
        break

      case Commands.SPLIT:
        await this.decodeSplit(index, variables, state, node, false)
        break

      case Commands.FOR:
        await this.decodeSplit(index, variables, state, node, true)
        break

      case Commands.SHIFT: {
        const scale = variables[1] / MEMORY_VALUE_MAX
        const move = variables[2] / MEMORY_VALUE_MAX
        const { bb } = state

        const width = (bb[2] - bb[0])
        const newWidth = width * scale
        const emptySpace = width - newWidth
        const offset = move * emptySpace

        const newState: State = {
          ...state,
          bb: [
            bb[0] + offset,
            bb[1],
            bb[0] + newWidth + offset,
            bb[3],
          ],
        }
        this.onBeat(index, node, variables, next[0])
        await this.drawNode(next[0], newState)
        break
      }

      case Commands.AB: {
        const { bb } = state
        const width = (bb[2] - bb[0]) / 2
        const height = (bb[3] - bb[1]) / 2

        const direction = variables[1] > 127

        await this.drawNode(next[direction ? 0 : 1], { ...state, bb: [bb[0], bb[1], bb[0] + width, bb[1] + height] })
        await this.drawNode(next[direction ? 1 : 0], { ...state, bb: [bb[0] + width, bb[1], bb[0] + width * 2, bb[1] + height] })
        await this.drawNode(next[direction ? 1 : 0], { ...state, bb: [bb[0], bb[1] + height, bb[0] + width, bb[1] + height * 2] })
        await this.drawNode(next[direction ? 0 : 1],
          { ...state, bb: [bb[0] + width, bb[1] + height, bb[0] + width * 2, bb[1] + height * 2] })

        break
      }

      case Commands.SWITCH: {
        const firstLargest = variables[0] > variables[1] ? 0 : 1
        const firstLargestValue = variables[firstLargest]
        const nextIndex = variables[2] > firstLargestValue ? 2 : firstLargest
        this.onBeat(index, node, variables, nextIndex)
        await this.drawNode(next[nextIndex], state)
        break
      }

      case Commands.TERMINATE_WHEN:
        if (variables[0] > variables[1]) {
          await this.drawNode(next[1], state)
        }
        break

      case Commands.MARGIN:
        await this.margin(index, MARGIN_SPACE, node, state)
        break

      case Commands.MULTIPLY:
        await this.drawNode(next[0], state)
        break

      case Commands.INCREMENT_REGISTER_1:
        this.incrementMemory(node, 1, 0)
        await this.drawNode(next[0], state)
        break

      case Commands.DECREMENT_REGISTER_1:
        this.incrementMemory(node, -1, 0)
        await this.drawNode(next[0], state)
        break

      case Commands.INCREMENT_REGISTER_2:
        this.incrementMemory(node, 1, 1)
        await this.drawNode(next[0], state)
        break

      case Commands.DECREMENT_REGISTER_2:
        this.incrementMemory(node, -1, 1)
        await this.drawNode(next[0], state)
        break

      case Commands.INCREMENT_REGISTER_3:
        this.incrementMemory(node, 1, 2)
        await this.drawNode(next[0], state)
        break

      case Commands.DECREMENT_REGISTER_3:
        this.incrementMemory(node, -1, 2)
        await this.drawNode(next[0], state)
        break

      case Commands.LINES:
        this.drawLines(state, this.currentColor(node), variables)
        await this.margin(index, MARGIN_LINE, node, state)
        break

      case Commands.FILL:
        rect(
          state,
          this.currentColor(node),
          null,
          this.svg,
          null,
          variables[1] > MEMORY_VALUE_MAX / 2,
          variables[2] / MEMORY_VALUE_MAX,
          variables[0] > MEMORY_VALUE_MAX / 2 ? 'linear' : 'radial',
        )
        await this.margin(index, MARGIN_LINE, node, state)
        break

      case Commands.STROKE:
        rect(
          state, null, this.currentColor(node),
          this.svg, null,
          variables[1] > MEMORY_VALUE_MAX / 2,
          variables[2] / MEMORY_VALUE_MAX,
          variables[0] > MEMORY_VALUE_MAX / 2 ? 'linear' : 'radial',
        )
        await this.margin(index, MARGIN_LINE, node, state)
        break

      case Commands.CIRCLE:
        circle(
          state, this.currentColor(node), this.svg,
          false,
          variables[1] > MEMORY_VALUE_MAX / 2,
          variables[2] / MEMORY_VALUE_MAX,
          variables[0] > MEMORY_VALUE_MAX / 2 ? 'linear' : 'radial',
        )
        await this.margin(index, MARGIN_LINE, node, state)
        break

      case Commands.SQUARE:
        circle(
          state, this.currentColor(node),
          this.svg, true,
          variables[1] > MEMORY_VALUE_MAX / 2,
          variables[2] / MEMORY_VALUE_MAX,
          variables[0] > MEMORY_VALUE_MAX / 2 ? 'linear' : 'radial',
        )
        await this.margin(index, MARGIN_LINE, node, state)
        break

      case Commands.CROOK:
        rect(
          state,
          this.currentColor(node),
          null,
          this.svg,
          variables[0],
          variables[1] > MEMORY_VALUE_MAX / 2,
          variables[2] / MEMORY_VALUE_MAX,
          variables[0] > MEMORY_VALUE_MAX / 2 ? 'linear' : 'radial',
        )
        await this.margin(index, MARGIN_LINE, node, state)
        break

      case Commands.WINDOW:
        await this.drawWindow(
          index,
          state,
          node,
          variables[0] > MEMORY_VALUE_MAX / 2,
          variables[1] > MEMORY_VALUE_MAX / 2,
          variables[2] > MEMORY_VALUE_MAX / 2,
          variables[2] / MEMORY_VALUE_MAX,
          variables[0] > MEMORY_VALUE_MAX / 2 ? 'linear' : 'radial',
        )
        break

      case Commands.TRIANGLE:
        triangle(
          state,
          this.currentColor(node),
          this.svg,
          variables[1] > 127,
          variables[2] > MEMORY_VALUE_MAX / 2,
          variables[2] / MEMORY_VALUE_MAX,
          variables[0] > MEMORY_VALUE_MAX / 2 ? 'linear' : 'radial',
        )
        await this.margin(index, MARGIN_LINE, node, state)
        break

      default:
        console.warn(`not yet implemented: ${command.toString()}`)
    }
  }

  async draw (): Promise<void> {
    if (this.aborted) return

    const initialState: State = {
      bb: viewportToBoundingBox(this.drawingSize),
    }
    this.grid.nodes.forEach(node => { node.touched = false })
    // await sleep(0)
    await this.drawNode(0, initialState)
    // await sleep(0)
  }

  abort (): void {
    this.aborted = true
  }
}
