import SVG from 'svg.js'
import { sleep, Viewport, px, viewport, isTouchDevice } from './utils'
import { BORDER_WIDTH } from './const'
import { GridSizeUnits, TOP_MARGE } from './gridSize'

export interface SVGInstance {
  size: Function
  clear: Function
  line: Function
  polygon: Function
  circle: Function
  rect: Function
  gradient: Function
  node: HTMLElement
}

export default class ImageFlipBuffer {
  private static readonly instances: ImageFlipBuffer[] = []
  public readonly element: HTMLDivElement
  public readonly drawingSize: Viewport
  public readonly svgInstances: [SVGInstance, SVGInstance]
  private drawingLayerIndex = 0
  private visibleLayerIndex = 1
  private readonly index: number = 0
  private static index = 0
  public readonly parentElement: HTMLDivElement

  public onClick: Function = (): void => {} // eslint-disable-line
  public onMouseOver: Function = (): void => {} // eslint-disable-line
  public onMouseOut: Function = (): void => {} // eslint-disable-line

  static factory (
    containerElement: HTMLDivElement,
    index: number,
    drawingSize: Viewport,
    gridSizeUnits: GridSizeUnits,
  ): ImageFlipBuffer {
    const v: Viewport = viewport()
    const cellWidth = (drawingSize.width + BORDER_WIDTH * 2)
    const cellHeight = (drawingSize.height + BORDER_WIDTH * 2)
    const imageFlipBuffer = new ImageFlipBuffer(containerElement, drawingSize)
    const { columns, rows } = gridSizeUnits
    const x = index % columns
    const y = Math.floor(index / columns)
    const xPos = x * cellWidth + (v.width - cellWidth * columns) / 2
    const yPos = y * cellHeight + (v.height - cellHeight * rows) / 2 + TOP_MARGE / 2

    imageFlipBuffer.x = xPos
    imageFlipBuffer.y = yPos
    return imageFlipBuffer
  }

  constructor (parentElement: HTMLDivElement, drawingSize: Viewport) {
    this.parentElement = parentElement
    this.element = document.createElement('div')
    this.element.className = 'image-file-buffer-container'
    parentElement.appendChild(this.element)
    this.drawingSize = drawingSize
    this.element.style.width = px(this.drawingSize.width)
    this.element.style.height = px(this.drawingSize.height)
    this.svgInstances = [SVG(this.element), SVG(this.element)]
    this.svgInstances.forEach(layer =>
      layer.size(this.drawingSize.width, this.drawingSize.height))

    if (!isTouchDevice) {
      this.element.addEventListener('mousedown', () => this.handleClick())
      this.element.addEventListener('mouseover', () => this.onMouseOver())
      this.element.addEventListener('mouseout', () => this.onMouseOut())
    } else {
      this.element.addEventListener('touchstart', () => this.handleClick())
    }
    this.index = ImageFlipBuffer.index++
    ImageFlipBuffer.instances.push(this)
  }

  remove () {
    this.parentElement.removeChild(this.element)
  }

  private handleClick (): void {
    this.onClick(this.index)
  }

  public get visibleSVG () {
    return this.svgInstances[this.visibleLayerIndex]
  }

  get svg () {
    return this.svgInstances[this.drawingLayerIndex]
  }

  private setCoordProperty (name: any, value: number) { // eslint-disable-line
    // this.svgInstances.forEach(instance => {
    //   instance.node.style[name] = `${value.toString()}px`
    // })
    this.element.style[name] = `${value.toString()}px`
  }

  set x (value: number) {
    this.setCoordProperty('left', value)
  }

  set y (value: number) {
    this.setCoordProperty('top', value)
  }

  set className (value: string) {
    this.svgInstances.forEach(instance => {
      instance.node.className = value
    })
  }

  public highlight (color = 'white') {
    this.svgInstances.forEach(instance => {
      instance.node.style.border = `${BORDER_WIDTH.toString()}px solid ${color}`
    })
  }

  public static async fadeOutAll () {
    for (let i = 0; i < ImageFlipBuffer.instances.length; i++) {
      const buffer = ImageFlipBuffer.instances[i]
      const svgToFade = buffer.svgInstances[buffer.visibleLayerIndex]
      const hiddenSvg = buffer.svgInstances[buffer.drawingLayerIndex]
      svgToFade.node.style.opacity = '0'
      hiddenSvg.clear()
      await sleep(200)
    }
  }

  private flipBuffers () {
    this.visibleLayerIndex = this.drawingLayerIndex
    this.drawingLayerIndex = this.visibleLayerIndex === 0 ? 1 : 0
  }

  public start () {
    const newBuffer = this.svg
    const oldBuffer = this.visibleSVG

    newBuffer.clear()
    newBuffer.node.style.transition = ''
    newBuffer.node.style.opacity = '0'
    newBuffer.node.style.zIndex = '200'
    oldBuffer.node.style.zIndex = '100'
  }

  public flip () {
    const svgToShow = this.svg
    svgToShow.node.style.transition = 'opacity 400ms'
    svgToShow.node.style.opacity = '1'

    this.flipBuffers()
  }
}
